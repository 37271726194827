<template>
  <div class="TablePage">
    <v-btn icon absolute style="top: 45%; background-color: #000; color: #fff; opacity: 0.5; z-index: 1"
      v-if="isArrowLeft">
      <v-icon>mdi-menu-left</v-icon>
    </v-btn>
    <v-btn icon absolute style="top: 45%; right: 0; background-color: #000; color: #fff; opacity: 0.5; z-index: 1"
      v-if="isArrowRight">
      <v-icon>mdi-menu-right</v-icon>
    </v-btn>
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-btn class="ma-2" depressed outlined :color="selectedButtonIndex === btnIndex ? 'primary' : 'grey darken-2'"
          v-for="(btn, btnIndex) in buttonGroup" :key="btnIndex" :disabled="$store.state.loading"
          @click="changeButtonGroup(btnIndex)">{{ btn.name }}</v-btn>
      </div>
      <div class="d-flex justify-end mt-2">
        <div class="d-flex" v-if="!notSearchFeature">
          <div class="d-flex align-center px-2">
            <v-btn text icon :disabled="$store.state.loading">
              <v-icon @click="refresh()">mdi-refresh</v-icon>
            </v-btn>
            <v-select v-model="nowSelectItem" :items="selectData" label="搜尋條件"></v-select>
          </div>
          <div class="d-flex align-center" v-if="searchSelect">
            <v-text-field clearable label="搜尋" :type="nowSelectItem === 'id' ? 'number' : 'text'" v-model="searchItem"
              append-icon="mdi-magnify" @keypress.enter="search(false, nowSelectItem, searchItem)"
              @click:append="search(false, nowSelectItem, searchItem)"></v-text-field>
          </div>
          <div class="d-flex align-center" v-else-if="nowSelectItem === 'isEnable'">
            <v-select v-model="nowSearchEableItem" :items="searchEable"></v-select>
            <div>
              <v-btn text icon :disabled="$store.state.loading"
                @click="search(true, nowSelectItem, nowSearchEableItem)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center" v-else-if="nowSelectItem === 'isOrderByAsc'">
            <v-select v-model="nowSearchEableItem" :items="sortGroup"></v-select>
            <div>
              <v-btn text icon :disabled="$store.state.loading"
                @click="search(true, nowSelectItem, nowSearchEableItem)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center" v-else-if="nowSelectItem === 'type'">
            <v-select v-model="nowSearchStatusItem" :items="typeGroup"></v-select>
            <div>
              <v-btn text icon :disabled="$store.state.loading"
                @click="search(true, nowSelectItem, nowSearchStatusItem)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center" v-else-if="nowSelectItem === 'isFromSample'">
            <v-select v-model="nowSearchStatusItem" :items="fromSampleGroup"></v-select>
            <div>
              <v-btn text icon :disabled="$store.state.loading"
                @click="search(true, nowSelectItem, nowSearchStatusItem)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center" v-else>
            <v-select v-model="nowSearchStatusItem" :items="searchStatus"></v-select>
            <div>
              <v-btn text icon :disabled="$store.state.loading"
                @click="search(true, nowSelectItem, nowSearchStatusItem)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="d-flex align-center pl-2" v-if="!notCreateBtn">
          <v-btn color="primary" @click="openDialog('create')" class="create_industryBtn mr-2">
            <v-icon class="mr-2">mdi-plus</v-icon>
            新增
          </v-btn>
          <v-btn color="info" @click="clickDetail()" class="create_industryBtn mr-2" v-if="isQaManagement">
            <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
            排序
          </v-btn>
        </div>
      </div>
    </div>
    <v-simple-table :id="targetName">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-for="(th, thIndex) in thead" :key="thIndex" style="white-space: nowrap"
              :style="th === '操作' ? 'position:sticky;right:0;background-color:#fff' : ''">
              {{ th }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index" v-show="data.length > 0">
            <td v-for="(keyItem, keyIndex) in keyGroup" :key="keyIndex" style="white-space: nowrap">
              <!-- <v-switch
                hide-details
                class="mt-0 pt-0"
                v-if="keyItem === 'isEnable'"
                v-model="item[keyItem]"
                icon
                color="primary"
                @change="changeisEnable(item.id, item[keyItem], item.dealerId)"
              >
              </v-switch> -->
              <div v-if="keyItem === 'createTime' ||
        keyItem === 'updateTime' ||
        keyItem === 'signInTime' ||
        keyItem === 'ceremonyTime' ||
        keyItem === 'timeOfDeath' ||
        keyItem === 'notifyTime' ||
        keyItem === 'start' ||
        keyItem === 'end'
        ">
                {{ item[keyItem] | dateTimeFilter }}
              </div>
              <div v-else-if="keyItem === 'arrivalDate' ||
        keyItem === 'passAwayDate' ||
        keyItem === 'passAwayLunarDate' ||
        keyItem === 'expireDate' ||
        keyItem === 'date'
        ">
                {{ item[keyItem] | dateFilter }}
              </div>
              <div v-else-if="keyItem === 'isFromSample'">
                {{ item[keyItem] ? "是" : "不是" }}
              </div>
              <div v-else>{{ item[keyItem] }}</div>
            </td>
            <td class="" style="position: sticky; right: 0; background-color: #fff">
              <div class="d-flex align-center">
                <div class="d-flex align-center"
                  v-if="item.isEnable !== undefined && targetName !== 'contractFlowTable'">
                  <label>啟用：</label>
                  <v-switch hide-details class="mt-0 pt-0" v-model="item.isEnable" icon color="primary"
                    @change="changeisEnable(item.id, item.isEnable, item.dealerId)">
                  </v-switch>
                </div>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickUpdateBtn(index)" icon color="primary" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-pencil</v-icon>
                        <div>編輯</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>編輯</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="haveContact">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="info" @click="clickDetail(item)" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-card-account-phone</v-icon>
                        <div>聯絡</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>聯絡方式</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="haveDetail">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="info" @click="clickDetail(item)" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-note</v-icon>
                        <div>詳細</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>詳細資訊</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="haveShare">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="info" @click="showShare(item)" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-share-variant</v-icon>
                        <div>分享</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>分享給用戶</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="havePlay">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="info" @click="goNewPage(item)" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-play</v-icon>
                        <div>播放</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>播放</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="havePlay">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="info" @click="openDialog('shareObituary')" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-qrcode</v-icon>
                        <div>分享</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>分享</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="isDealerManagement">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openAllFile(item)" icon color="success" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-file-cabinet</v-icon>
                        <div>檔案</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>檔案</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="isCusomterRelation">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickDetail(item)" icon color="info" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-account-group</v-icon>
                        <div>合約</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>合約</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="canDelete">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickDelete(item)" icon color="red" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-delete</v-icon>
                        <div>刪除</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>刪除</span>
                </v-tooltip>
                <v-tooltip bottom color="black" v-if="loginDealer">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickChangeDealer(item)" icon color="red" v-bind="attrs" v-on="on">
                      <div>
                        <v-icon>mdi-swap-horizontal</v-icon>
                        <div>登入</div>
                      </div>
                    </v-btn>
                  </template>
                  <span>登入業者</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <tr v-show="data.length <= 0" class="text-center">
            <td :colspan="thead.length" style="font-weight: 900">無資料</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "TablePage",
  props: [
    "targetName",
    "thead",
    "data",
    "keyGroup",
    "selectData",
    "buttonGroup",
    "selectedButtonIndex",
    "haveDetail",
    "haveShare",
    "havePlay",
    "haveContact",
    "isDealerManagement",
    "selectedTabIndex",
    "notCreateBtn",
    "notSearchFeature",
    "isCusomterRelation",
    "loginDealer",
    "canDelete",
    "isQaManagement",
    "shareCode",
  ],
  data: () => ({
    nowSelectItem: {},
    nowSearchEableItem: "true",
    nowSearchStatusItem: {},
    searchSelect: true,
    searchEable: [
      { text: "啟用", value: "true" },
      { text: "未啟用", value: "false" },
    ],
    fromSampleGroup: [
      { text: "是", value: "true" },
      { text: "否", value: "false" },
    ],
    typeGroup: [
      { text: "固定節日", value: "0" },
      { text: "合約相關", value: "1" },
    ],
    searchStatus: [
      { text: "等待審核", value: "0" },
      { text: "正常", value: "1" },
      { text: "等待", value: "2" },
      { text: "鎖定", value: "3" },
      { text: "禁用", value: "4" },
      { text: "移除", value: "5" },
    ],
    sortGroup: [
      { text: "排序", value: true },
      { text: "不排序", value: false },
    ],
    searchItem: "",
    isArrowLeft: false,
    isArrowRight: true,
  }),
  methods: {
    clickDelete(data) {
      this.$emit("clickDelete", data);
    },
    clickChangeDealer(data) {
      this.$emit("clickChangeDealer", data);
    },
    changeButtonGroup(index) {
      this.$emit("changeButtonGroup", index);
    },
    statusJudge() {
      const routePage = this.$route.params.page;
      switch (routePage) {
        case "OperatorManagement":
          this.searchStatus = [
            { text: "等待審核", value: "0" },
            { text: "正常", value: "1" },
            { text: "等待", value: "2" },
            { text: "鎖定", value: "3" },
            { text: "禁用", value: "4" },
            { text: "移除", value: "5" },
          ];
          break;
        case "IndustryManagement":
          this.searchStatus = [
            { text: "等待審核", value: "0" },
            { text: "正常", value: "1" },
            { text: "等待", value: "2" },
            { text: "鎖定", value: "3" },
            { text: "禁用", value: "4" },
            { text: "移除", value: "5" },
          ];
          break;
        case "ContractManagement":
          if (this.selectedTabIndex === undefined) {
            this.searchStatus = [
              { text: "協調中", value: "1" },
              { text: "進行中", value: "2" },
              { text: "已圓滿", value: "3" },
              { text: "已取消", value: "4" },
            ];
          } else if (this.selectedTabIndex === 3) {
            this.searchStatus = [
              { text: "草稿", value: "0" },
              { text: "未發送", value: "1" },
              { text: "已送達", value: "2" },
              { text: "取消", value: "3" },
            ];
          }
          break;
        case "DealerManagement":
          this.searchStatus = [
            { text: "等待審核", value: "0" },
            { text: "正常", value: "1" },
            { text: "停用", value: "2" },
            { text: "禁用", value: "3" },
          ];
          break;
        case "CustomerManagement":
          this.searchStatus = [
            { text: "等待審核", value: "0" },
            { text: "正常", value: "1" },
            { text: "停用", value: "2" },
            { text: "禁用", value: "3" },
          ];
          break;
        default:
          break;
      }
    },
    selectSearch() {
      if (
        this.nowSelectItem === "isEnable" ||
        this.nowSelectItem === "status" ||
        this.nowSelectItem === "isFromSample" ||
        this.nowSelectItem === "type" ||
        this.nowSelectItem === "isOrderByAsc"
      ) {
        this.searchSelect = false;
      } else {
        this.searchSelect = true;
      }
    },
    openAllFile(item) {
      this.$emit("openAllFile", item);
    },
    clickUpdateBtn(index) {
      this.$emit("clickUpdateBtn", index);
    },
    changeisEnable(id, boolean, dealerId) {
      this.$emit("changeisEnable", id, boolean, dealerId);
    },
    clickDetail(item) {
      this.$emit("clickDetail", item);
    },
    showShare(item) {
      this.$emit("showShare", item);
    },
    async search(enableBoolean, nowSelectItem, data) {
      try {
        await this.$emit("search", enableBoolean, nowSelectItem, data);
      } catch (err) { }
    },
    refresh() {
      this.searchItem = "";
      this.$emit("search");
    },
    openDialog(action) {
      this.$emit("openDialog", action);
    },
    goNewPage(data) {
      window.open(`${Vue.prototype.ssrURL}eObituaries/${data.dealerContractId}`, "_blank");
    },
    handleScroll() {
      const main = document.getElementById(this.targetName);
      const table = main.getElementsByClassName("v-data-table__wrapper")[0];
      table.scrollLeft = 0;
      let myWidth = table.scrollWidth - table.clientWidth;
      if (myWidth === 0 && myWidth - table.scrollLeft === 0) {
        this.isArrowLeft = false;
        this.isArrowRight = false;
      }
      table.addEventListener("scroll", () => {
        let needWidth = myWidth - table.scrollLeft;
        if (table.scrollLeft >= myWidth || needWidth < 2) {
          this.isArrowLeft = true;
          this.isArrowRight = false;
        } else if (table.scrollLeft <= 0) {
          this.isArrowLeft = false;
          this.isArrowRight = true;
        } else if (myWidth === 0 && needWidth === 0) {
          this.isArrowLeft = false;
          this.isArrowRight = false;
        } else {
          this.isArrowLeft = true;
          this.isArrowRight = true;
        }
      });
    },
  },
  created() {
    this.nowSelectItem = this.selectData[0].value;
    this.statusJudge();
  },
  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
    });
  },
  watch: {
    nowSelectItem() {
      this.searchItem = "";
      this.selectSearch();
    },
    selectedTabIndex() {
      this.searchItem = "";
      this.nowSelectItem = this.selectData[0].value;
      this.nowSearchEableItem = "true";
      this.nowSearchStatusItem = {};
      this.searchSelect = true;
      this.isArrowLeft = true;
      this.isArrowRight = false;
      this.statusJudge();
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    selectedButtonIndex() {
      this.searchItem = "";
      this.nowSelectItem = this.selectData[0].value;
      this.nowSearchEableItem = "true";
      this.nowSearchStatusItem = {};
      this.searchSelect = true;
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
  },
};
</script>
<style>
.v-text-field__details {
  display: none;
}
</style>
