<template>
  <div id="EObituary">
    <v-card>
      <v-card-title> {{ action === "update" ? "修改" : "創建" }}電子訃聞 </v-card-title>

      <v-card-text>
        <v-row v-if="page === 1">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                class="mr-1"
                label="名稱"
                hide-details
                :rules="rules"
                v-model="data.name"
              ></v-text-field>
              <v-text-field
                class="mr-1"
                clearable
                :rules="rules"
                label="往生者名稱"
                hide-details
                v-model="data.deceasedName"
              ></v-text-field>
              <v-text-field
                clearable
                :rules="rules"
                label="往生者年紀"
                hide-details
                type="number"
                v-model="data.deceasedAge"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <DateTimePicker
                :label="'過世時間'"
                :value="data.timeOfDeath"
                :keyValue="'timeOfDeath'"
                @confirmDateTime="confirmDateTime"
                class="mr-1"
              />
              <DateTimePicker
                :label="'儀式時間'"
                :value="data.ceremonyTime"
                :keyValue="'ceremonyTime'"
                @confirmDateTime="confirmDateTime"
                class="mr-1"
              />
              <v-text-field
                class=""
                :rules="rules"
                clearable
                label="儀式地址"
                hide-details
                v-model="data.ceremonyAddress"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-text-field
                clearable
                class="mr-1"
                :rules="rules"
                label="儀式地方"
                hide-details
                v-model="data.ceremonyPlace"
              ></v-text-field>
              <v-text-field
                class="mr-1"
                clearable
                :rules="rules"
                label="聯絡人"
                hide-details
                v-model="data.contactPerson"
              ></v-text-field>
              <v-text-field
                clearable
                :rules="rules"
                label="聯絡電話"
                hide-details
                v-model="data.contactTel"
              ></v-text-field>
            </div>
            <div class="d-flex mb-2">
              <v-select
                :items="templateGroup"
                class="mr-1"
                item-text="name"
                item-value="id"
                :rules="rules"
                label="範本"
                hide-details
                v-model="data.templateId"
              ></v-select>
              <v-select
                :items="musicGroup"
                class="mr-1"
                item-text="name"
                :rules="rules"
                item-value="id"
                label="音樂"
                hide-details
                v-model="data.musicId"
              ></v-select>
              <v-menu
                v-model="menuforExpireDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.expireDate"
                    class="mr-1"
                    :rules="rules"
                    label="到期日期"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    @click="menuforExpireDate = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="zh-cn"
                  v-model="data.expireDate"
                  @input="menuforExpireDate = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <v-textarea
              class="input"
              style="width: 100%"
              hide-details
              label="備註"
              v-model="data.memo"
            ></v-textarea>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row v-if="page === 2">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div class="d-flex align-end">
              <div>
                <div>
                  <h3 style="font-weight: 900; color: #333" class="pb-2">選擇往生者圖片</h3>
                </div>
                <div class="d-flex align-end">
                  <img
                    v-if="fileUrl"
                    :src="fileUrl"
                    style="width: 15vw; min-width: 100px; aspect-ratio: 1/1; object-fit: cover"
                    class="mr-2"
                  />
                  <input
                    id="upload"
                    ref="imageUploader"
                    type="file"
                    @click="resetImageUploader"
                    @change="uploadImageFile"
                    accept=".png, .jpg, .jpeg"
                  />
                  <CroppiePhoto
                    v-if="!isNewMediaDialog"
                    :isCroppie="isCroppie"
                    :photoUrl="fileUrl"
                    @finishCropPhoto="finishCropPhoto"
                    @cancelCrop="cancelCrop"
                  />
                </div>
                <div class="red--text" v-if="oversize">圖片超過5 MB!</div>
              </div>
              <div class="d-flex align-center">
                <div
                  class="mr-2"
                  style="font-weight: 900"
                  v-if="data.media && data.media.length > 0"
                >
                  已加入{{ data.media.length }}個附件
                </div>
                <button
                  class="primary px-2 py-1 white--text"
                  style="border-radius: 3px"
                  @click="openNewMedia()"
                >
                  新增其他附件
                </button>
              </div>
            </div>
            <div class="d-flex align-center">
              <v-checkbox
                class="ma-0 py-1"
                color="indigo"
                label="顯示邀請內文"
                hide-details
                v-model="data.isShowContent"
                @change="twoPageDisable"
              ></v-checkbox>
              <v-checkbox
                class="ma-0 py-1"
                color="indigo"
                label="顯示回函連結"
                hide-details
                v-model="data.isShowReply"
                @change="twoPageDisable"
              ></v-checkbox>
              <v-checkbox
                class="ma-0 py-1"
                color="indigo"
                label="顯示地圖"
                hide-details
                v-model="data.isShowMap"
                @change="twoPageDisable"
              ></v-checkbox>
              <v-checkbox
                class="ma-0 py-1"
                color="indigo"
                label="顯示致贈花卉"
                hide-details
                v-model="data.isShowSponsorFlower"
                @change="twoPageDisable"
              ></v-checkbox>
            </div>
            <div v-if="data.isShowReply">
              <v-text-field
                clearable
                label="回函連結"
                hide-details
                v-model="data.replyUrl"
                @input="twoPageDisable"
              ></v-text-field>
              <div class="red--text">請輸入址標頭(http://或是https://)</div>
            </div>
            <div v-if="data.isShowContent">
              <v-textarea
                class="input"
                style="width: 100%"
                hide-details
                @input="twoPageDisable"
                label="邀請內文"
                v-model="data.content"
              ></v-textarea>
            </div>
            <div v-if="data.isShowSponsorFlower">
              <v-radio-group
                @change="changeSponsorFlowerType()"
                v-model="data.sponsorFlowerType"
                row
              >
                <v-radio label="致贈花卉資訊" :value="0"></v-radio>
                <v-radio label="致贈花卉網址" :value="1"></v-radio>
              </v-radio-group>
              <div class="">
                <v-textarea
                  v-if="data.sponsorFlowerType === 0"
                  class="input"
                  style="width: 100%"
                  @input="twoPageDisable"
                  hide-details
                  label="花卉資訊"
                  v-model="data.sponsorFlowerInfo"
                ></v-textarea>
                <div v-else-if="data.sponsorFlowerType === 1">
                  <v-text-field
                    clearable
                    label="花卉連結"
                    @input="twoPageDisable"
                    hide-details
                    v-model="data.sponsorFlowerUrl"
                  ></v-text-field>
                  <div class="red--text">請輸入址標頭(http://或是https://)</div>
                </div>
              </div>
            </div>
            <div>
              <v-text-field
                label="承辦單位資訊"
                clearable
                v-model="data.dealerContactInfo"
                @input="twoPageDisable"
                hide-details
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="clickCloseBtn()"> 取消 </v-btn>
        <v-btn color="primary darken-1" text @click="page -= 1" v-if="page === 2"> 上一步 </v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="page += 1"
          v-if="page === 1"
          :disabled="nextDisable"
        >
          下一步
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="clickConfirmBtn()"
          v-if="page === 2"
          :disabled="nextDisable || twoDisabled"
        >
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="isNewMediaDialog"
      width="90%"
      max-width="1200"
      scrollable
      @click:outside="cancelMediaDialog"
      @keydown.esc="cancelMediaDialog"
    >
      <v-card style="min-height: 300px" class="pa-2">
        <CroppiePhoto
          :isCroppie="isCroppieAttachment"
          :photoUrl="modifyUrl"
          @finishCropPhoto="CropPhotoFile"
          @cancelCrop="cancelCropForAttachment"
        />
        <input
          id="attachmentUpload"
          type="file"
          style="border-radius: 0px"
          @change="mediaFileChange"
          class="my-2"
          ref="uploadMedia"
        />
        <div class="d-flex flex-wrap" style="min-height: 300px">
          <div
            v-for="(item, index) in mediaUrls"
            :key="index"
            style="width: 200px; height: 200px; position: relative"
            class="mb-2 mx-2"
          >
            <v-btn
              fab
              absolute
              x-small
              style="right: -12px; top: -12px"
              color="red"
              class="white--text"
              @click="removeItem(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <img
              :src="item.url"
              v-if="item.type === 'image'"
              style="height: 100%; object-fit: cover; aspect-ratio: 1/1"
            />
            <video v-else-if="item.type === 'video'" style="width: 100%; height: 100%" controls>
              <source :src="item.url" type="video/mp4" />
            </video>
            <div v-else>{{ item.fileName }}</div>
          </div>
        </div>
        <div class="d-flex justify-end py-2">
          <v-btn color="red darken-1" text @click="cancelMediaDialog"> 取消 </v-btn>
          <v-btn color="primary darken-1" text @click="uploadMedia"> 確認 </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CroppiePhoto from "../CroppiePhoto.vue";
import { fileUpload } from "@/apis/FileUpload";
import { updateMediaEObituary } from "@/apis/EObituary";
import DateTimePicker from "../DateTimePicker.vue";
export default {
  name: "EObituary",
  props: ["action", "data", "dealerData", "dealerConactGroup", "templateGroup", "musicGroup"],
  components: { DateTimePicker, CroppiePhoto },
  data: () => ({
    fileUrl: "",
    fileForm: null,
    twoDisabled: true,
    rules: [(value) => !!value || "必填"],
    baseUrl: "",
    page: 1,
    menuforExpireDate: false,
    oversize: false,
    isCroppie: false,
    isNewMediaDialog: false,
    mediaUrls: [],
    mediaForms: [],
    modifyUrl: "",
    isCroppieAttachment: false,
  }),
  methods: {
    async uploadMedia() {
      let data = [];
      let i = 1;
      this.mediaForms.forEach(async (item, index) => {
        let form = new FormData();
        form.append("file", item);
        const res = await fileUpload(form);
        if (res.status === 200) {
          data.push({
            eObituaryId: this.data.id,
            mdeiaUrl: res.data.result.url,
            mimeType: this.mediaUrls[index].mediaType,
          });
        }
        i += 1;
      });
      const timer = setInterval(async () => {
        if (i > this.mediaForms.length) {
          clearInterval(timer);
          this.data.media = [...this.data.media, ...data];
          if (this.action === "update") {
            await updateMediaEObituary(this.data.id, this.data.media);
          }
          this.cancelMediaDialog();
        }
      }, 1000);
    },
    cancelMediaDialog() {
      this.mediaUrls = [];
      this.mediaForms = [];
      this.isNewMediaDialog = false;
    },
    openNewMedia() {
      if (this.data.media) {
        this.data.media.forEach((item) => {
          this.mediaUrls.push({
            type: item.mimeType.split("/")[0],
            mediaType: item.mimeType,
            url: this.baseUrl + item.mdeiaUrl,
            fileName: "",
          });
        });
      } else {
        this.data.media = [];
      }
      this.isNewMediaDialog = true;
    },
    cancelCrop() {
      this.isCroppie = false;
      document.getElementById("upload").value = "";
      this.fileUrl = "";
      this.fileForm = null;
    },
    cancelCropForAttachment() {
      this.isCroppieAttachment = false;
      document.getElementById("attachmentUpload").value = "";
      this.mediaUrls.pop();
      this.mediaForms.pop();
      this.modifyUrl = "";
      this.$forceUpdate();
    },
    async CropPhotoFile(url) {
      this.mediaUrls[this.mediaUrls.length - 1].url = url;
      const item = this.mediaForms.pop();
      const file = await this.urltoFile(url, item.name, item.type);
      this.mediaForms.push(file);
      this.isCroppieAttachment = false;
      this.$forceUpdate();
    },
    async finishCropPhoto(url) {
      this.fileUrl = url;
      const file = await this.urltoFile(url, this.fileForm.name, this.fileForm.type);
      this.fileForm = file;
      this.isCroppie = false;
      this.$forceUpdate();
      this.twoPageDisable();
    },
    changeSponsorFlowerType() {
      if (this.data.sponsorFlowerType === 0) {
        this.data.sponsorFlowerUrl = "";
      } else {
        this.data.sponsorFlowerInfo = "";
      }
      this.twoPageDisable();
    },
    confirmDateTime(dateTime, key) {
      this.data[key] = dateTime;
      this.$forceUpdate();
    },
    resetImageUploader() {
      this.oversize = false;
      this.$refs.imageUploader.value = "";
    },
    mediaFileChange(event) {
      if (event.target.files && event.target.files[0]) {
        const fileName = event.target.files[0].name;
        const reader = new FileReader();
        const type = event.target.files[0].type.split("/");
        reader.onload = async () => {
          if (reader !== null) {
            this.mediaUrls.push({
              type: type[0],
              mediaType: event.target.files[0].type,
              url: reader.result,
              fileName: fileName,
            });
            this.mediaForms.push(event.target.files[0]);
            this.$refs.uploadMedia.value = null;
            if (type[0] == "image") {
              this.isCroppieAttachment = true;
              this.modifyUrl = reader.result;
            }
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    removeItem(index) {
      if (this.mediaUrls[index].fileName === "") {
        this.data.media.splice(index, 1);
      } else {
        let findIndex = -1;
        this.mediaForms.forEach((item, itemIndex) => {
          if (item.name === this.mediaUrls[index].fileName) {
            findIndex = itemIndex;
          }
        });
        if (findIndex > -1) {
          this.mediaForms.splice(findIndex, 1);
        }
      }
      this.mediaUrls.splice(index, 1);
    },
    async uploadImageFile(event) {
      if (event.target.files && event.target.files[0]) {
        const fileName = event.target.files[0].name;
        const reader = new FileReader();
        const type = event.target.files[0].type.split("/");
        reader.onload = async () => {
          if (reader !== null) {
            this.fileUrl = reader.result;
            this.fileForm = event.target.files[0];
            // this.isCroppie = true;
            // this.doUpload(event.target.files[0]);
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    async urltoFile(url, filename, mineType) {
      return await fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mineType }));
    },
    async doUpload() {
      if (this.fileForm) {
        var form = new FormData();
        form.append("file", this.fileForm);
        const res = await fileUpload(form);
        if (res.status === 200) {
          this.data.deceasedPicUrl = res.data.result.url;
        }
        this.twoPageDisable();
      }
    },
    clickCloseBtn() {
      this.page = 1;
      this.$emit("clickCloseBtn");
    },
    filterDateTime(date) {
      return date.replaceAll("-", "").replaceAll(" ", "").replaceAll(":", "");
    },
    async clickConfirmBtn() {
      await this.doUpload();
      this.data.isEnable = true;
      this.data.timeOfDeath = this.filterDateTime(this.data.timeOfDeath);
      this.data.ceremonyTime = this.filterDateTime(this.data.ceremonyTime);
      this.data.expireDate = this.filterDateTime(this.data.expireDate);
      this.page = 1;
      this.$emit("clickConfirmBtn", this.data);
    },
    switchDateTime(dateTime) {
      const date = dateTime.toString().split("");
      const year = Number(date.splice(0, 4).toString().replaceAll(",", ""));
      const month = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const day = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const hours = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      const minutes = Number(date.splice(0, 2).toString().replaceAll(",", ""));
      return `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day} ${
        hours >= 10 ? hours : "0" + hours
      }:${minutes >= 10 ? minutes : "0" + minutes}`;
    },
    twoPageDisable() {
      this.twoDisabled =
        !this.fileUrl ||
        (this.data.isShowReply && !this.data.replyUrl) ||
        (this.data.isShowContent && !this.data.content) ||
        (this.data.isShowSponsorFlower &&
          !(this.data.sponsorFlowerType === undefined || this.data.sponsorFlowerType < 0) &&
          !this.data.sponsorFlowerUrl &&
          !this.data.sponsorFlowerInfo);
    },
  },
  created() {
    this.baseUrl = this.$store.state.StorageDomain;
    if (this.action === "update") {
      this.data.timeOfDeath = this.switchDateTime(this.data.timeOfDeath);
      this.data.ceremonyTime = this.switchDateTime(this.data.ceremonyTime);
      const expireDate = this.data.expireDate.toString().split("");
      expireDate.splice(4, 0, "-");
      expireDate.splice(7, 0, "-");
      this.data.expireDate = expireDate.toString().replaceAll(",", "");
      this.fileUrl = this.baseUrl + this.data.deceasedPicUrl;
    }
  },
  watch: {
    action(value) {
      if (value === "update") {
        this.data.timeOfDeath = this.switchDateTime(this.data.timeOfDeath);
        this.data.ceremonyTime = this.switchDateTime(this.data.ceremonyTime);
        const expireDate = this.data.expireDate.toString().split("");
        expireDate.splice(4, 0, "-");
        expireDate.splice(7, 0, "-");
        this.data.expireDate = expireDate.toString().replaceAll(",", "");
        this.fileUrl = this.baseUrl + this.data.deceasedPicUrl;
      } else {
        this.fileUrl = "";
        this.fileForm = null;
      }
    },
    page(value) {
      if (value === 2) {
        this.twoPageDisable();
      }
    },
  },
  computed: {
    nextDisable() {
      return (
        !this.data.name ||
        !this.data.deceasedAge ||
        !this.data.timeOfDeath ||
        !this.data.ceremonyTime ||
        !this.data.ceremonyPlace ||
        !this.data.ceremonyAddress ||
        !this.data.contactPerson ||
        !this.data.contactTel ||
        this.data.templateId === undefined ||
        this.data.templateId < 0 ||
        this.data.musicId === undefined ||
        this.data.musicId < 0 ||
        !this.data.expireDate
      );
    },
  },
};
</script>
